import { TransfersModule } from './modules/transfers/transfers.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth.guard';
import { LoginFormComponent } from './shared/components/login-form/login-form.component';
import { SpinnerComponent } from './shared/layout/spinner/spinner.component';
import { Business } from './core/models/user/businessData';
import { WeBoostYourClinicComponent } from './modules/contracting/we-boost-your-clinic/we-boost-your-clinic.component';

const routes: Routes = [
  {
    path: 'login', component: LoginFormComponent,
  },
  {
    path: 'equipo', loadChildren: () => import("./modules/team/team.module").then(m => m.TeamModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'denarius-finance', loadChildren: () => import("./modules/business/business.module").then(m => m.BusinessModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'empresas', loadChildren: () => import("./modules/business/business.module").then(m => m.BusinessModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'particulares', loadChildren: () => import("./modules/individual/individual.module").then(m => m.IndividualModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'autonomos', loadChildren: () => import("./modules/freelance/freelance.module").then(m => m.FreelanceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tarjetas', loadChildren: () => import("./modules/cards/cards.module").then(m => m.CardsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contratacion', loadChildren: () => import("./modules/contracting/contracting.module").then(m => m.ContractingModule),
    canActivate: [AuthGuard],
  },
/*   {
    path: 'transferencias', loadChildren: () => import("./modules/transfers/transfers.module").then(m => m.TransfersModule),
    canActivate: [AuthGuard],
  }, */
  {
    path: 'facturas', loadChildren: () => import("./modules/billing/billing.module").then(m => m.BillingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contacto', loadChildren: () => import("./modules/contact/contact.module").then(m => m.ContactModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'impulsamos-tu-clinica', component: WeBoostYourClinicComponent,
  },
  {
    path: '**',
    redirectTo: 'equipo',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
