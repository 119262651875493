import { environment } from './../environments/environment.uat';
import { Router } from '@angular/router';
import { Component, HostListener, OnInit, Input } from '@angular/core';
import { AuthService } from './core/services';
import { NavigationBarModel } from './core/models/navBar/navBar';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatDrawerMode } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'denarius-backoffice';
  public nav!: boolean;
  public breakpoint = 768
  public burgerMenuActive: boolean = false;
  _isLoggedIn!: boolean;
  public mode: MatDrawerMode = 'over';
  
  
  
  constructor(
    public breakpointObserver: BreakpointObserver,
    private router: Router, 
    private auth: AuthService,
  ){}

  ngOnInit(): void {
    this.auth.setTokenSessionStorage()
    this.auth.getTokenSessionStorage().subscribe( 
      res => {
        this._isLoggedIn = res
    })
  }
}
